import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { navigateToUrl } from "single-spa";
import classNames from "classnames";
import { useSearchParams } from "react-router-dom";
import { Button, ButtonLoader, Loader, ProductLogo, SnackbarBanner, TypographyComponent } from "gx-npm-ui";
import { getAsyncRequest, postAsyncRequest } from "gx-npm-lib";
import styles from "./onboarding.styles.module.scss";
import OnboardingBackgroundLeft from "./onboarding-background-left.png";
import OnboardingBackgroundRight from "./onboarding-background-right.png";

const OnboardingComponent = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const [productName, setProductName] = useState("");

  const [productImageLoc, setProductImageLoc] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingInitiative, setLoadingInitiative] = useState(false);
  const [errorInitiative, setErrorInitiative] = useState(false);

  const pid = searchParams.get("pid") || "";
  const tid = searchParams.get("tid") || "";

  useEffect(() => {
    if (!tid) {
      navigateToUrl("/s/home");
      return;
    }
    (async () => {
      setLoading(true);
      try {
        const response = await getAsyncRequest(
          `/api/v2/data/user-home/onboarding?templateId=${tid}${pid ? `&productId=${pid}` : ""}`
        );
        if (response.status !== 200 || !response.data?.data) {
          throw new Error();
        }
        setProductName(response.data.data.productName);
        setProductImageLoc(response.data.data.productImageLoc);
        setTemplateName(response.data.data.templateName);
      } catch (err) {
        navigateToUrl("/s/home");
      }
      setLoading(false);
    })();
  }, [pid, tid, setLoading]);

  const handleGetStarted = () => {
    (async () => {
      setLoadingInitiative(true);
      try {
        const weightedPriorities = JSON.parse(sessionStorage.getItem(`weightedPriorities-${tid}`) || "[]");
        const payload = { templateId: Number(tid), weightedPriorities };
        const url = "/api/v3/initiatives/";
        const response = await postAsyncRequest(url, payload);
        const initiativeId = response?.data?.data?.initiativeId || "";
        if (response.status === 201 && !!initiativeId) {
          localStorage.setItem("showRibbonAnimation", "true");
          sessionStorage.removeItem(`weightedPriorities-${tid}`);
          navigateToUrl(`/s/evaluation/${initiativeId}/overview`);
        } else {
          setErrorInitiative(true);
        }
      } catch (err) {
        setErrorInitiative(true);
      }
      setLoadingInitiative(false);
    })();
  };

  const handleClose = () => {
    navigateToUrl("/s/home");
  };

  return (
    <>
      <div className={styles.backgroundNav} />
      <div className={styles.backgroundImageContainer}>
        <img alt="" className={styles.backgroundImageLeft} src={OnboardingBackgroundLeft} />
        <img alt="" className={styles.backgroundImageRight} src={OnboardingBackgroundRight} />
      </div>
      <div aria-label={t("onboarding")} className={styles.container}>
        <div className={styles.contentContainer}>
          <h1 className={styles.headingContainer}>
            <TypographyComponent rootClassName={styles.headingElement} color="carbon" element="span" styling="h1">
              {t("Welcome to your")}
            </TypographyComponent>
            <TypographyComponent rootClassName={styles.headingElement} color="yam" element="span" styling="h1">
              {t("BuySmart")}
            </TypographyComponent>
            <TypographyComponent rootClassName={styles.headingElement} color="carbon" element="span" styling="h1">
              {t("evaluation")}
            </TypographyComponent>
          </h1>
          <TypographyComponent color="iron" styling="p1">
            {t(
              "A BuySmart evaluation will allow you to manage all of your evaluation activities involving this market in one convenient location."
            )}
          </TypographyComponent>
          <div className={styles.divider} />
          {loading && <Loader size={100} />}
          {!loading && templateName && productName && (
            <>
              <div className={styles.marketInterestContainer}>
                <TypographyComponent boldness="medium" color="iron" rootClassName={styles.marketInterest} styling="p3">
                  {t("Market of interest")}
                </TypographyComponent>
                <TypographyComponent color="carbon" styling="p1">
                  {templateName}
                </TypographyComponent>
              </div>
              <div className={styles.vendorInterestContainer}>
                <TypographyComponent boldness="medium" color="iron" rootClassName={styles.vendorInterest} styling="p3">
                  {t("Vendor of interest")}
                </TypographyComponent>
                <div className={styles.productContainer}>
                  <ProductLogo
                    imageHeight="40"
                    imageWidth="40"
                    logo={productImageLoc}
                    name={productName}
                    rootClassName={styles.productLogo}
                  />
                  <TypographyComponent boldness="medium" color="carbon" styling="p1">
                    {productName}
                  </TypographyComponent>
                </div>
              </div>
              <div className={styles.actionContainer}>
                <ButtonLoader
                  btnClass="btn-primary"
                  isLoading={loadingInitiative}
                  onClick={handleGetStarted}
                  rootClassName={classNames(styles.btnGetStarted)}
                >
                  {t("Let's get started")}
                </ButtonLoader>
                <Button disabled={loadingInitiative} onClick={handleClose}>
                  {t("I'm not ready yet")}
                </Button>
              </div>
            </>
          )}
        </div>
        <SnackbarBanner
          isDefaultErrorMessage={true}
          isOpen={errorInitiative}
          setIsOpen={() => setErrorInitiative(false)}
          type="ERROR"
        />
      </div>
    </>
  );
};

export default OnboardingComponent;
