import {
  FileTypeDocIcon,
  FileTypeDocxIcon,
  FileTypeJPegIcon,
  FileTypeMovIcon,
  FileTypeMP4Icon,
  FileTypePdfIcon,
  FileTypePNGIcon,
  FileTypePPTIcon,
  FileTypePPTxIcon,
  FileTypeTextIcon,
  FileTypeXlsIcon,
  FileTypeXlsxIcon,
} from "gx-npm-icons";
import { ValidFileExtension } from "./file-extension-badge.types";
import styles from "./file-extension-badge.styles.module.scss";

type FileExtensionDisplayProps = { extension: ValidFileExtension };
const FileExtensionBadgeComponent = ({ extension }: FileExtensionDisplayProps) => {
  return (
    <div className={styles.root}>
      {extension === "doc" && <FileTypeDocIcon />}
      {extension === "docx" && <FileTypeDocxIcon />}
      {(extension === "jpeg" || extension === "jpg") && <FileTypeJPegIcon />}
      {extension === "mov" && <FileTypeMovIcon />}
      {extension === "mp4" && <FileTypeMP4Icon />}
      {extension === "pdf" && <FileTypePdfIcon />}
      {extension === "png" && <FileTypePNGIcon />}
      {extension === "ppt" && <FileTypePPTIcon />}
      {extension === "pptx" && <FileTypePPTxIcon />}
      {extension === "txt" && <FileTypeTextIcon />}
      {extension === "xls" && <FileTypeXlsIcon />}
      {extension === "xlsx" && <FileTypeXlsxIcon />}
    </div>
  );
};

export default FileExtensionBadgeComponent;
